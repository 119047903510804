import React, { useState, useEffect, useRef } from "react";

// const sections={
//   clarification:{
//     p:<div>
//     <div>
//     איזה כיף שהצטרפת!

//     </div>
//     <div>
//       <b>
//       לשים ❤️ בסוף 
//       </b>
//     הטופס תקבלו קישור חיצוני לתשלום
// אנא וודאו שהטופס באמת מולא עד סופו ונשלח באמצעות כפתור ה"שליחה"
//     </div>
//     <div style = {{color:"rgb(217, 48, 37)"}}>
//     כוכבית (*) מציינת שאלה שאי אפשר לדלג עליה
//     </div>
//   </div>,

//   title:"",
//   skippable:true
    
//   }
// }

// function Section(props){
//   console.log(props)
//    const title = props.sectionData.title
//    const skippable = props.sectionData.skippable
//    const p = props.sectionData.p
//   return (
//     <div style={styles.warrperSection}>
//       <div>
//         {title}
//       {p}
//       </div>
//     </div>
//   )
// }
// function Form (){
//   return (
//     <div style={styles.warrperForm}>
//       <div style={styles.title}>
//           הרשמה לאימונים פתוחים 2024-2025
//       </div>
//       <Section sectionData={sections.clarification}/>
//     </div>
//   )
// }

/////////////////////////////////////////

function removeBeforeWord(str, word) {
  // מוצא את האינדקס הראשון של המילה במחרוזת
  var index = str.indexOf(word);

  // אם המילה נמצאה, גזור את המחרוזת מהמיקום של המילה ועד הסוף
  if (index !== -1) {
      return str.substring(index);
  }

  // אם המילה לא נמצאה, החזר את המחרוזת המקורית
  return str;
}

// דוגמה לשימוש:
// var myString = "Hello, this is a test string with a keyword here.";
// var result = removeBeforeWord(myString, "keyword");
//console.log(result);  // יוחזר: "keyword here."

const GoogleForm = () => {
  console.log("start")

  const [iframeHeight, setIframeHeight] = useState("600"); // גובה התחלתי
  const [formSubmitted, setFormSubmitted] = useState(false); // מעקב אחרי האם הטופס נשלח
  const iframeRef = useRef(null);
  const formUrl = "https://docs.google.com/forms/d/e/1FAIpQLSd5Z4cpSmzl1gjgO0oejJv9lak_mrim6HtVvYDbmukcVQCMOA/viewform?embedded=true"
  const [isLoaded, setIsLoaded] = useState(false)
  const [currentUrl,setCurrentUrl] = useState(null)
  const [changePage,setChangePage] = useState(false)
  const [countChangePage,setCountChangePage] = useState(0)
  let isChange = 0
  const handleLoad = ()=>{
    console.log("Iframe loaded", countChangePage + 1);
    setCountChangePage(prevCount => prevCount + 1); // עדכון הספירה בכל פעם שה-iframe נטען מחדש
    setCurrentUrl(iframeRef.current?.src); // שמירת ה-URL הנוכחי
  }
  const checkIframeUrl = () => {
    const iframe = iframeRef.current;
console.log("iframe",iframe)
var htmlContent = document.documentElement.innerText;
var myString =htmlContent
var result = removeBeforeWord(myString, "באמצעות ");
 var x = removeBeforeWord(result, "#document");
console.log("result:",result)
  };
  useEffect(() => {
    let iframe = iframeRef.current;
console.log("iframeRef",iframeRef)
    while(iframe==null){
      iframe = iframeRef.current
    }
    if(!isLoaded){
      console.log("isLoaded")
      iframe.addEventListener("load", handleLoad);
      setIsLoaded(true)
      setCurrentUrl( iframeRef.current.src)

    }
    const interval = setInterval(() => {
      checkIframeUrl();
    }, 10000); // בדיקה כל שניה

    return () => clearInterval(interval);
  
  }, []);
  if(iframeRef.current!=null){
    console.log("currentUrl",currentUrl)
  }
console.log(isChange)
  return (
    <div>
          {countChangePage==2
           ? (
       <LastPage/>
      ) : ( <iframe
        ref={iframeRef}
        src={formUrl}
        width="100%"
        height={iframeHeight}
        title="Google Form"
      />)}
       
    </div>
  );
};
const LastPage = ()=>{
return(
  <div style={styles.warrper}>
  <h3>הרשמה לאימונים פתוחים 2024-2025</h3>
  <div style={styles.link}>
  <a  href="https://www.hugim.org.il/HugimWeb.dll/gwuJcU-9W83pNdDpP0UTZnqyAa4/?Tickets=1&KodMatnas=357">
    נשאר רק לקנות את המנוי המתאים פה 
   </a>
  </div>
  
</div>
)
}
const styles = {
  warrperForm:{
    display:"flex",
    flex:1,
    flexDirection: "column",
  //   justifyContent: "center",
  // alignItems:"center",
  marginBottom:20,
  textAlign:"right",

  },
title:{
  backgroundColor:"#fff",
fontWeight:700,
fontSize:"24pt",
display:"flex",
  flex:1,
  justifyContent: "flex-end",
  alignItems:"flex-start",
    margin:"10px 10px 0px 10px" 


},
warrperSection:{
  borderBottom:"solid rgb(32, 33, 36) 1px",
  backgroundColor:"#fff",
  display:"flex",
  flex:1,
  //flexDirection: "column",
  // justifyContent: "flex-end",
  // alignItems:"flex-end",
  margin:"0px 10px 10px 10px" 
},

  warrper:{
    display:"flex",
    flex:1,
    flexDirection: "column",
    justifyContent: "center",
  alignItems:"center",
  marginBottom:20
  },
  link:{
    fontSize:25,
    color:"red"
  }

}
export default GoogleForm;