import React, { useState,useEffect, useRef } from 'react';
//import { pdfjs } from 'pdfjs-dist/build/pdf';
import * as pdfjsLib from 'pdfjs-dist';
import { pdfjs } from 'pdfjs-dist/webpack';
import pdf from "../images/fullRegulations.pdf"//"../../public/fullRegulations.pdf"

const movePage = (i,numOfPagesToMove)=>{
    const allPages=[1,2,3,4,5]
    if(i==5&&numOfPagesToMove>0){
        i = 1
        return i
    }
    if(i==1&&numOfPagesToMove<0){
        i = 5
        return i 
    }
    i= i+numOfPagesToMove
return i
}
const PdfViewer = ({ pdfUrl }) => {
  const canvasRef = useRef(null);
const [numberPage,setNumberPage] = useState(1)
const [isRendering,setIsRendering] = useState(false)
 useEffect(() => {
    if (isRendering) return; // אם רינדור קיים, אל תעשה רינדור נוסף
    setIsRendering(true)
    const renderPDF = async () => {
           
        try {
             const  loadingTask = await pdfjsLib.getDocument(pdf);
  
          const pdfDocument = await loadingTask.promise;
          const page = await pdfDocument.getPage(numberPage);
  
          const scale = 2;
          const viewport = page.getViewport({ scale });
  
          const canvas = canvasRef.current;
          const context = canvas.getContext('2d');
          const devicePixelRatio = window.devicePixelRatio || 1;
          
          canvas.height = viewport.height * devicePixelRatio;
          canvas.width = viewport.width * devicePixelRatio;
          const dataUrl = canvas.toDataURL();
          console.log("Canvas data URL:", dataUrl);
          context.clearRect(0, 0, canvas.width, canvas.height);
     
        
          context.scale(devicePixelRatio, devicePixelRatio);
          //context.translate(canvas.width / 2, canvas.height / 2); // תרגם למרכז הקנבס
          // context.translate(-canvas.width / 2, -canvas.height / 2); // תחזור למיקום ההתחלתי
           
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
  
        } catch (error) {
          console.error("Error loading or rendering PDF:", error);
        }finally {
            setIsRendering(false); // שחרור מצב הרינדור בסיום או במקרה של שגיאה
          }
      };
    renderPDF();

 },[numberPage]);

  return(
    <div>
        <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }}></canvas>
        <div style={stlyes.warrperButtons}>
            <button onClick={()=>setNumberPage(movePage(numberPage,1))}>קדימה</button>
            <button onClick={()=>setNumberPage(movePage(numberPage,-1))}>אחורה</button>
        </div>
    </div>
  ) ;
};

const stlyes = {
    warrperButtons:{
        display:"flex",
        flex:1,
             justifyContent: "space-around",

    }
}

export default PdfViewer;








// import React, { useState, useEffect } from "react";
// import { Document, Page } from 'react-pdf';
// import firstSection from "../images/2 page.png"
// import pdf from "../images/fullRegulations.pdf"//"../../public/fullRegulations.pdf"
// function Regulations() {

//     return (
// <embed src={pdf} style={{ width: '100%', height: '100vh', border: 'none' }} type="application/pdf"/>
//             // <iframe src={pdf} width={"100%"} height={"700px"}/>
//     //   <div style={stlyes.warrper}>
//     //     {/* <Title/>
//     //     <P1/> */}
//     //   </div>
//     );
// }
// function Title (){
//     return (
//         <div>
//             תקנון אימונים פתוחים
//         </div>
//    )
// }
// function P1(){
//     return(
//         <div style={stlyes.p1}>
//             <b>
//                 .על כל מתאמנ.ת באימון םתוח לקרוא תקנון זה ולהחזירו מאושר וחתום
//             על כל מתאמנ.ת באימון פתוח לקרוא תקנון זה ולהחזירו מאושר וחתום.
//             </b>
//             <div>
//             :מושגים במסמך
//             <div>
//              מתאמנ.ת - נרשם.ת לאימונים הפתוחים ● 
//             </div>
//             <div>
//              צוות - חבר.ת קואופרטיב ו/או עובד.ת ו/או מתנדב.ת מטעם הקואופרטיב ●

//             </div>
//             </div>
         
//         </div>
//     )
// }
// const stlyes={
//     warrper:{
//         display:"flex",
//         alignItems:"center",
//         flexDirection: "column",
//         color:"#fff",
//        // flex:1
//     },
//     warrperIFram:{
//         display:"flex",
//         flex:1,
//     },
//     title:{
//         size:20
//     },
//     p1:{
//         display:"flex",
//         flex:1,
//         flexDirection: "column",
//         alignItems:"flex-end", 
//     justifyContent: "center",
//       //  color:"red"
//         backgroundColor:"red"
//     }

// }
// export default Regulations;
