import { CHANGE_LOADER_STATUS, CHANGE_LOADER_TYPE, CHANGE_MODE } from "./types";
import { LOG_OUT } from "../userData/types";

const initState = {
  mode: 1,
  loader: {
    loaderStatus: false,
    type: "normal"
  }
};
export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_LOADER_STATUS:
      return changeLoaderStatus(state, action);
    case CHANGE_LOADER_TYPE:
      return changeLoaderType(state, action);
    case CHANGE_MODE:
      return changeMode(state, action);

    case LOG_OUT:
      return logOut(state, action);

    default:
      return state;
  }
}
const changeLoaderStatus = (state, action) => {
  let loaderStatus = action.payload;

  return {
    ...state,
    loader: {
      ...state.loader,
      loaderStatus
    }
  };
};
const changeLoaderType = (state, action) => {
  let type = action.payload;
  console.log("reucser", type);
  return {
    ...state,
    loader: {
      ...state.loader,
      type
    }
  };
};
const changeMode = (state, action) => {
  const modeNumber = action.payload;
  return {
    ...state,
    mode: modeNumber
  };
};
const logOut = () => {
  return initState;
};
