import { CHANGE_BALANCE, CREATE_NEW_ADDRESS, LOG_IN, LOG_OUT } from "./types";

const initState = {
  balance: 0,
  widthdrawalAddress: "",
  depositAddress: {
    depositAddress: "",
    showDepositAddress: false
  },
  password: {
    showPassword: false,
    password: ""
  }
};
export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_BALANCE:
      return changeBalance(state, action);
    case CREATE_NEW_ADDRESS:
      return createNewAddress(state, action);
    case LOG_IN:
      return logIn(state, action);
    case LOG_OUT:
      return logOut(state, action);
    default:
      return state;
  }
}
const changeBalance = (state, action) => {
  const balance = action.payload;
  return {
    ...state,
    balance: balance
  };
};
const createNewAddress = (state, action) => {
  const addressData = action.payload;
  const password = addressData.password;
  //const depositAddress = addressData.depositAddress;
  return {
    ...state,
    // depositAddress: {
    //   ...state.depositAddress,
    //   depositAddress,
    //   showDepositAddress: true
    // },
    password: { ...state.password, password, showPassword: true }
  };
};
const logIn = (state, action) => {
  const depositAddress = action.payload.depositAddress;
  const password = action.payload.password;
  const widthdrawalAddress = action.payload.widthdrawalAddress;
  console.log("1", action.payload);
  return {
    ...state,
    widthdrawalAddress,
    depositAddress: {
      ...state.depositAddress,
      depositAddress,
      showDepositAddress: true
    },
    password: {
      ...state.password,
      password
    }
  };
};
const logOut = (state, action) => {
  return initState;
};
