import { CHANGE_STAKE, CHANGE_CURRENT_GAME,CHANGE_LOTTERY_PRIZE } from "./types";
import { LOG_OUT } from "../userData/types";
const initState = {
  allGames: ["throwingDice", "lottery"],
  currentGame: 0,
  throwingDice: {
    title: "Throwing dice",
    stake: 10
  },
  lottery: {
    title: "Lottery",
    stake: 100,
    address:
      // "kaspatest:qp0hpjmm5qxsn0rgjes7jqfuzxejqxx9wgmhvqmr3ksvgsdh0gxr5h4q6y8eq"
      "kaspa:qz30rzpc9krva3mfsc5uz4mx5d202ujnsyykw8gy3ymywzwkaz7j2mq8qws3j",
      prize:{first:0,second:0,third:0 }
  }
};
export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_STAKE:
      return changeStake(state, action);
    case CHANGE_CURRENT_GAME:
      return changeCurrentGame(state, action);
    case LOG_OUT:
      return logOut(state, action);
      case CHANGE_LOTTERY_PRIZE:
      return changeLotteryPrize(state,action)

    default:
      return state;
  }
}
const changeStake = (state, action) => {
  let stake = action.payload;
  let currentGame = state.allGames[state.currentGame];
  switch (currentGame) {
    case "throwingDice":
      stake = stake < 10 ? 10 : stake;
      stake = stake > 100 ? 100 : stake;
      break;
    case "lottery":
      stake = Math.floor(stake / 100);
      stake = stake * 100;

      break;

    default:
      return state;
  }

  return {
    ...state,

    [currentGame]: { ...state[currentGame], stake }
  };
};
const changeCurrentGame = (state, action) => {
  const gameIndex = action.payload;
  return {
    ...state,

    ...state.games,
    currentGame: gameIndex
  };
};
const changeLotteryPrize = (state,action)=>{
const fullPoll = action.payload
//normal time
// const allThePrizes =fullPoll*0.7
// const firstPrize =allThePrizes *0.50
// const secondPrize =allThePrizes *0.35
// const thirdPrize =allThePrizes *0.15

//dk funding
const allThePrizes =fullPoll*0.2
return{
  ...state,
  lottery:{
    ...state.lottery,
    //prize:{first:firstPrize,second:secondPrize,third:thirdPrize }
    prize:{first:allThePrizes }
  }

}
}
const logOut = () => {
  return initState;
};
