import React, { useState } from "react";
import useWindowDimensions from "../../modules/hooks/useGetWindowDimendions";
import { useSelector, useDispatch } from "react-redux";
const Button = props => {
  const [isHover, setIsHover] = useState(false);
  const [isPress, setIsPress] = useState(false);
  const {width,height} = useWindowDimensions()

  const onClick = props.onClick;
  const text = props.text;
  const onHover = props.onHover;
  let style = { ...styles.button};

  if(width<=480){
    style = {...styles.button,...stylingPhone.button}
  }
  style = { ...style, ...props.style };

  if (isHover) {
    const styleOnHover = {
      ...style,
      ...styles.onHover,
      ...props.style,
      ...props.styleOnHover
    };
    style = styleOnHover;
  }
  if (isPress) {
    style = { ...style, ...props.style };
  }
  return (
    <button
      onMouseDown={() => {
        setIsPress(true);
      }}
      onMouseUp={() => {
        setIsPress(false);
      }}
      onMouseEnter={() => {
        setIsHover(true);
        if (onHover) {
          onHover();
        }
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      style={style}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const styles = {
  button: {
    backgroundColor: "#0BA776",
   // size: 20,
    border: "none",
    fontSize: 16,
    padding: "10px 15px",
    borderRadius: 50,
    color: "#fff",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  }
};
const stylingPhone = {
  button:{
    fontSize: 25,
    padding: "4px 6px",
    borderRadius: 50,
  }

}
styles.onHover = {
 // ...styles.button,

  backgroundColor: hexColorDivision(styles.button.backgroundColor),
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
};

function hexColorDivision(strHex, withHashSign = true) {
  //without  retrun # second arrgument false
  let rBorderColorHex;
  let gBorderColorHex;
  let bBorderColorHex;
  let newStr = withHashSign ? "#" : "";
  if (strHex.length == 4) {
    rBorderColorHex = parseInt(strHex.slice(1, 2), 16);
    gBorderColorHex = parseInt(strHex.slice(2, 3), 16);
    bBorderColorHex = parseInt(strHex.slice(3, 4), 16);
  } else {
    rBorderColorHex = parseInt(strHex.slice(1, 3), 16);
    gBorderColorHex = parseInt(strHex.slice(3, 5), 16);
    bBorderColorHex = parseInt(strHex.slice(5, 7), 16);
  }
  rBorderColorHex = Math.floor(rBorderColorHex * 0.7).toString(16);
  gBorderColorHex = Math.floor(gBorderColorHex * 0.7).toString(16);
  bBorderColorHex = Math.floor(bBorderColorHex * 0.7).toString(16);
  rBorderColorHex =
    rBorderColorHex.length == 1 ? "0" + rBorderColorHex : rBorderColorHex;
  gBorderColorHex =
    gBorderColorHex.length == 1 ? "0" + gBorderColorHex : gBorderColorHex;
  bBorderColorHex =
    bBorderColorHex.length == 1 ? "0" + bBorderColorHex : bBorderColorHex;
  newStr = newStr + rBorderColorHex + gBorderColorHex + bBorderColorHex;
  return newStr;
}
export default Button;
