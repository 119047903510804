import React, { useState, useEffect } from "react";
import Button from "./htmlTags/Button"
import { useSelector, useDispatch } from "react-redux";
import Regulations from "./regulations";
import "../App.css";
import useWindowDimensions from "../modules/hooks/useGetWindowDimendions";
import GoogleForm from "./GoogleForm";

function App() {
  let styles = stylesLaptop;
  const { width, height } = useWindowDimensions();
  if (width <= 480) {
    styles = stylesPhone;
  }
  return (
    <div style={styles.warrper} className="App">
      
        <GoogleForm/>
      <Popup/>
    </div>
  );
}

function Popup(){
   let styles = stylesLaptop;
  const { width, height } = useWindowDimensions();
  if (width <= 480) {
    styles = stylesPhone;
  }
  const [isActiv, setIsActiv] = useState(false);
  if(!isActiv){
    return(
      <div style={styles.warrperButton}>
        <Button fontSize={20} text="לתקנון המלא" onClick={()=>setIsActiv(true)}>לתקנון</Button>
      </div>
    )

  }
  return(
    <div style={styles.warrperPopUp}>
      <Regulations/>     
    
      <div>
      <Button fontSize={20} text="קראתי ואני מסכים" onClick={()=>setIsActiv(false)}/>
      </div>
    </div>
  )

  
}
const stylesLaptop = {
  warrper: {
    display: "flex",
    flex: 1,
    backgroundColor: "#edf2fa",
    flexDirection: "column",
  },
  warrperForm:{
    display:"flex",
   // flex:1,
  //   justifyContent: "center",
  // alignItems:"center",
//  backgroundColor:"red",
  
},
  warrperButton:{
    display: "flex",
    justifyContent: "flex-end",
  //alignItems:"flex-start",
    flex:10,
    
  },
  warrperPopUp:{
    display: "flex",
   // justifyContent: "center",
    alignItems:"center",
   flexDirection: "column",
    flex:1,
    backgroundColor:"rgba(90,90,90,0.7)",
    position: "fixed",
    top:0,
    buttom:0,
    height:"100%",
    width:"100%"
  },
  // text:{
  //   color:"#fff"
  // }
  // warrperMode: {
  //   display: "flex",
  //   flex: 1,
  //   flexDirection: "column",
  //   justifyContent: "flex-start"
  //   //alignItems:"flex-start"
  // },
  // warrperSecondLine: {
  //   display: "flex"
  //   //flex:1,
  //   //:"space-around",
  // },
  // warrperLogOut: {
  //   display: "flex",
  //   flex: 1
  //   // paddingLeft:30
  // },
  // warrperSeconderyTitle: {
  //   display: "flex",
  //   flex: 2,
  //   justifyContent: "center"
  //   // backgroundColor:"blue"
  // },
  // warrperUserData: {
  //   display: "flex",
  //   flexDirection: "column"
//  }
};

const stylesPhone = {
  ...stylesLaptop,
  warrperUserData: {
    ...stylesLaptop.warrperUserData,
    //marginTop: 30,
   // marginBottom: 30
  }
};
export default App;
