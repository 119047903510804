import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import userData from "./redux/userData/reducer";
import app from "./redux/app/reducer";
import games from "./redux/games/reducer";
const root = ReactDOM.createRoot(document.getElementById("root"));
const reducers = combineReducers({
  app,
  userData,
  games
});
const store = createStore(reducers);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// const store = createStore(reducers);
// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>
//   //document.getElementById("root")
// );
